.ImageUploadModal {
  color: #5e5873;
  .modal-body {
    padding: 0px 23px 23px 23px;
    font-weight: 400;
  }
  .input-label {
    font-size: 16px;
    margin-bottom: 22px;
    user-select: none;
  }
  .input {
    margin-bottom: 17px;
    font-size: 14px;
  }
  .upload-icon {
    font-size: 80px;
    margin-bottom: 5px;
    cursor: pointer;
  }
  .upload-text {
    font-size: 16px;
    user-select: none;
  }
  .link-like {
    color: #5279ce;
    text-decoration: underline;
    cursor: pointer;
    &:active {
      color: #3d5a97;
    }
  }

  .dotted-border {
    border: dashed 2px var(--primary);
    border-radius: 10px;
    padding: 18px 0;
  }
}
