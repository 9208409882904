.InfraList {
  .widgets-row {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    > div {
      flex: 1;
    }
    .InventoryWidget {
      height: 12.6rem;
    }
  }
  #infra-bod{
    height: min(calc(100vh - 390px), 900px);
    overflow: auto;
  }
  #infra-bod::-webkit-scrollbar-track {
    margin-top: 50px;
  }
  .fw-graph-div {
    width: 90%;
  }
  .bg-color {
    background-color: rgba(78, 116, 197, 0.15) !important;
    svg{
      rect{
        fill: none;
      }
      path{
        fill: #BABFC7;
      }
    }
  }
  .bg-fail {
    background-color: rgba(225, 0, 0, 0.1) !important;
    transition: all 0.5s ease-in-out ;
  }
  .hoverable {
    &:hover {
      background-color: rgba(235, 235, 235, 0.612);
      transition: all 0.2s ease-in-out;
    }
  }
  .hover-bold {
    &:hover {
      color: #5789DE;
    }
  }
  .csv-download-icon {
    height: '24px';
    width: '24px';
  }
  .OrgWidget {
    #org-list{
      height: min(calc(100vh - 410px), 900px);
      overflow: auto;
    }
  }
}