.ApMockUp {
  .border-big {
    border: 1px solid #e2e5e7;
  }

  .fill-grey {
    path {
      fill: grey;
    }
  }

  .fill-black {
    path {
      fill: #000;
    }
  }

  .fill-yellow {
    path {
      fill: #ffbe40;
    }
  }

  .uplink-poe-icon {
    position: absolute;
    top: -10px;
    right: -5px;
  }
}

.port-label {
  font-size: 1rem;
}

.firstRowAP {
  font-size: 0.875rem;
  font-weight: 500;
}

.secondRowAP {
  font-size: 0.875rem;
  font-weight: 600;
  text-transform: capitalize;
}