.Devices {
  .info-icon{
    margin-left: 0.35rem;
    font-size: 1.3rem;
    transform: translateY(0.3rem);
    color: var(--primary);
  }
  #venue-device-list{
    height: min(calc(100vh - 450px), 900px);
    overflow: auto;
  }
  #venue-device-list::-webkit-scrollbar-track {
    margin-top: 50px;
  }
  .searchRow {
    margin-bottom: 0.5rem;
  }
  .searchRow[data-no-info=true] {
    margin-bottom: 2.45rem;
  }
  .hoverable {
      &:hover {
        background-color: rgba(215, 214, 215, 0.367);
        transition: all 0.2s ease-in-out;
      }
    }
    .bg-color {
      background-color: rgba(82, 121, 206, 0.15) !important;
    }
  .hover-bold {
    text-decoration: underline;
    font-weight: bold;
    &:hover {
      color: dodgerblue;
      cursor: pointer;
    }
  }
  .table {
    table-layout: fixed;
  }
  .table thead th {
    border-bottom: none;
  }
  td {
    overflow-wrap: break-word;
  }
  .longValue {
    overflow-wrap: normal;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .quickfilter-icon {
    --icon-size: 22px;
    height: var(--icon-size);
    width: var(--icon-size);
    path {
      fill: #808080;
    }
  }
  .name--margin {
    margin-left: 25px;
  }
  .device-name-span {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: anywhere;
    padding-top: 0.15rem;
  }
  .infra-count {
    --bubble-size: 0.9rem;
    position: absolute;
    // font-size: 4px;
    font-size: 0.5rem;
    height: var(--bubble-size);
    width: var(--bubble-size);
    transform: translateX(-50%) translateY(-50%);
    top: 20%;
    left: 100%;
    border-radius: 50%;
    background-color: var(--primary);
    color: white;
  }
  .multi-click {
    color: #3E5ECF;
    font-weight: 500;
  }
  .pill {
    width: 33px;
    min-width: auto;
  }
  .white-bg {
    background-color: white;
    transition: all 0.2s ease-in-out;
  }
  // th, td {
  //   padding: 0.5rem;
  // }
}