.AvailableLayouts {
  .infinite-scroll-component__outerdiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  user-select: none;

  .cursor-grabbing {
    cursor: grab !important;
  }

  .layout-list-container {
    overflow: auto;
    max-height: calc(100vh - 300px);
  }

  .Tab {
    text-decoration: none;
    color: black;
    margin-right: 1.3em;
    padding-bottom: 0.6em;
    min-width: 10em;
    &:not(.ActiveTab):hover {
      border-bottom: 2px solid rgb(154, 161, 253);
      color: black;
    }
  }
  .ActiveTab {
    border-bottom: 3px solid dodgerblue;
    background-color: var(--bs-light);
    color: black;
  }
  .BlockedTab {
    cursor: not-allowed !important;
  }
  .upload-new-image-container {
    border: dashed 2px black;
    border-radius: 5px;
    height: 12em;
  }
  .AvailableLayoutsItem {
    min-height: 150px;
    border: solid 1px #cacaca;
  }
}
