.default-tag {
  background-color: #E8E8E8;
  padding: 0.1rem 0.4rem;
  display: flex;
  border-radius: 5px;
  text-align: center;
  font-weight: 500;
  font-size: 0.85rem;
  margin-left: 1rem;
  height: fit-content;
}

.OrgWidget {
  .ExpRow {

    .collapse-icon,
    .expand-icon {
      margin-right: 4px;
      min-width: 2.2rem;
      cursor: pointer;
    }

    .empty-space {
      min-width: 1.5rem;
    }

    .font--point-75rem {
      font-size: 0.75rem;
    }

    .small-black-dot {
      margin-left: 5px;
      margin-right: 5px;
      height: 5px;
      width: 5px;
      content: ' ';
      border-radius: 50%;
      background-color: black;
    }

    .second-line {
      margin-top: 0.2rem;
      min-height: 0.5rem;
      font-size: 0.8rem;
    }

    .padding-right--24px {
      padding-right: 24px;
    }

    .width--120px {
      width: 120px;
    }

    .alarm-dash-pointer {
      width: 6px;
      height: 7px;
      background-color: #444444;
      // border: 1px solid #444444;
      // border-radius: 6px;
      clip-path: circle();
      margin-right: 4px;
      margin-left: 4px;
    }
  }

  .ellipsed-text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .long-text-div {
    width: 110px;
  }

  .dropdown-menu {
    z-index: 40;
  }

  .grp-btn-icon-div {
    line-height: 0;
    min-width: 0;
    height: 100%;
    color: var(--text-color);
    padding: 0.2rem;
    height: 2.5rem;
    width: 2.24rem;
  }

  .csv-download-icon {
    height: '24px';
    width: '24px';
  }

  #org-list::-webkit-scrollbar-track {
    margin-top: 3.5625rem;
  }

  .bg-color--light-blue {
    background-color: #eff5ff !important;

    &:hover {
      background-color: #eff5ff !important;
    }
  }

  .opacity-50 {
    opacity: 0.5;
  }

  .font-size--larger {
    // font-size: 17px;
    font-size: 1.2142857142857142857142857142857rem;
  }

  .font-weight--600 {
    font-weight: 600;
  }

  .list-icon {
    line {
      stroke: black;
    }

    rect {
      stroke: black;
    }
  }

  .top-dropdown {
    z-index: 41;

    .dropdown-item {
      gap: 0.5rem;
    }
  }

  .list-download-icon--disabled {
    cursor: not-allowed;
  }

  .BulkActions {
    height: 100%;
  }

  input[type='checkbox'] {
    width: 15px;
    height: 15px;
    position: relative;
  }

  input[type='checkbox']:indeterminate::before {
    content: '';
    display: block;
    color: white;
    width: 15px;
    height: 15px;
    border-radius: 2px;
    background-color: #0f3fa6;
  }

  input[type='checkbox']:indeterminate:disabled::before {
    background-color: #cecfd1 !important;
  }

  input[type='checkbox']:indeterminate::after {
    content: '';
    display: block;
    width: 70%;
    height: 16%;
    background-color: transparent;
    border-radius: 10px;
    // border: solid white;
    // border-width: 2px 0 0 0;
    position: absolute;
    top: 42%;
    left: 16%;
  }

  .online-infra-counter {
    font-size: 12px;

    .normal-size {
      font-size: 14px;
    }

    .font-weight--600 {
      font-weight: 600;
    }
  }

  .all-task-done-check {
    position: absolute;
    top: 0;
    left: 4px;
  }

  .all-task-bg {
    background-color: #e5f5ef;
    height: 30px;
    padding-top: 2px;
    border: 1px solid #28a745;
    border-radius: 5px;
  }

  .live-black-icon {
    svg {
      #g {
        path {
          fill: #000;
        }
      }

      path {
        stroke: #000;
      }

      line {
        stroke: #000
      }
    }
  }

  .devolping-black-icon {
    svg {
      path {
        stroke: #000;
      }

      line {
        stroke: #000
      }

      polyline {
        stroke: #000;
      }
    }
  }

  .deployed-black-icon {
    svg {
      path {
        fill: #000;
      }

      line {
        stroke: #000
      }

      polyline {
        stroke: #000;
      }

      circle {
        stroke: #000;
      }
    }
  }
}