.SingleInfraSelector {
    .site-bg {
        background-color: #eff5ff !important;
    }
    .opacity-50 {
        opacity: 0.5;
    }
    #single-infra-selector {
        height: 65vh;
        overflow: auto;
    }
    .font-weight--600 {
        font-weight: 600
    }

    #single-infra-selector::-webkit-scrollbar-track{
        margin-top: 42px;
    }
}