.SwitchOverview {
  .material-symbols-outlined.filled {
    font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 48;
  }

  .status-symbol {
    font-size: 2.1em;
  }

  .success-badge {
    background-color: #289a71;
    border-radius: 5px;
    margin-right: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100px;
    min-height: 35px;
  }

  .client-badge {
    border: 1px solid #289a71;
    color: #289a71;
    font-size: 0.9rem;
    margin-top: 0.2rem;
    border-radius: 5px;
    padding: 0.1rem 1rem;
  }

  .fail-badge {
    background-color: #e02b1d;
    border-radius: 5px;
    margin-right: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100px;
    min-height: 35px;
  }

  .warning-badge {
    background-color: #e6af09;
    border-radius: 5px;
    margin-right: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100px;
    min-height: 35px;
  }

  .status-text {
    color: #fff;
    font-size: 1rem;
    font-weight: 600;
  }

  .dark-badge {
    background-color: #808080;
    border-radius: 5px;
    margin-right: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100px;
    min-height: 35px;
  }

  .previewer-image {
    background-color: #fff;
    border: 1px solid lightgray;
    height: 73px;
    width: 73px;
    padding: 7.55px 6.29px;
    border-radius: 6.29px;
  }

  .info-heading {
    color: rgba(110, 124, 135, 1);
    font-size: 0.8rem;
    display: flex;
    justify-content: space-between;
  }

  .info {
    font-size: 1rem;
    font-weight: 800;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .bg-grey {
    background-color: rgb(249, 249, 249);
  }

  .dropdownToggle {
    margin-left: 1rem;

    &:hover {
      cursor: pointer;
      background-color: rgba(211, 211, 211, 0.122);
    }
  }

  .networkBadge {
    border-radius: 10px;
    margin-right: 1em;
    margin-bottom: 1em;
  }

  .console-frame {
    height: 80vh;
  }

  .quicklink-button-ticket {
    padding: 0.4rem 1rem;
    position: relative;
    display: inline-block;
    border: 2px solid #eaeaea;
    margin: 0 0.5rem;

    .main-text {
      font-size: 1rem;
      color: #333;
      margin-bottom: 0.2rem;
    }

    .sub-text {
      font-size: 0.75rem;
    }
  }

  .bold-text {
    font-weight: 500;
  }

  .grey-img-dot {
    width: 5px;
    height: 5px;
    background-color: #d9d9d9;
    border-radius: 50%;
    margin-right: 4px;
  }

  .selected-img-dot {
    width: 8px;
    height: 5px;
    background-color: #444444;
    border-radius: 5px;
    margin-right: 4px;
  }

  .box {
    color: white;
    padding: 2px 4px;
    font-size: 12px;
    max-height: 1.9em;
  }

  .box.mode2g {
    background-color: #5279ce;
  }

  .box.mode5g {
    background-color: #50497a;
  }

  .box.mode6g {
    background-color: #86089c;
  }

  .float-this-right {
    position: absolute;
    right: 0;
  }

  .badge-custom {
    width: fit-content !important;
    display: inline !important;
  }

  .success-top-icon {
    position: absolute;
    top: -8px;
    right: -8px;
  }

  .success-top-icon-small {
    position: absolute;
    top: -8px;
    right: 0px;
  }

  .yellow-info-badge {
    background-color: #fff9e5;
    max-width: fit-content;
  }

  .copy-icon {
    visibility: hidden;
  }

  .copy-icon-active {
    visibility: visible;
  }

  .room-text-box {
    text-wrap: wrap;
    word-break: break-all;
  }

  .ellipse-text-box {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    // text-wrap: wrap;
    // word-break: break-all;
  }
}