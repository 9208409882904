.ExpTable {
  display: table;
  min-width: 100%;
  overflow: auto;
  padding-bottom: 1rem;
  background-color: transparent;
}
.ExpRow {
  display: table-row;
  background-color: #fff;
  &:hover {
    background-color: #f6f6f676 !important;
  }
}
.ExpCell {
  .straight-lines-tree-cell {
    position: absolute;
    top: 0;
    height: 100%;
    border-left: 2px solid var(--shasta-black);
  }
  .horizontal-dash-tree-cell {
    border-top: 2px solid var(--shasta-black);
    margin: 0;
    padding: 0;
    margin-right: 0.5rem;
    width: /*!props.hasExpandIcon ? "4.05rem" :*/ 1.5rem;
  }
}
// .hover-cell{
//     background-color: #fff;
//     :hover{
//         background-color: #EAEAEA;
//     }
// }
.parent-cell {
  // border-bottom: 1px solid #eaeaea;
  border-top: 2px solid #eaeaea;
}
.parent-cell-expand {
  position: relative;
  border-top: 2px solid #eaeaea;
}
.parent-cell-expand:after {
  content: ' ';
  position: absolute;
  top: 99%;
  left: 15%;
  width: 85%;
  height: 1%;
  // border-bottom: 1px solid #eaeaea;
}
.table-head {
  :first-child {
    border-top-left-radius: 10px;
  }
  :last-child {
    border-top-right-radius: 10px;
  }
}
// .table-col{
//     display: table-cell;
//     background-color: #E9EDF7 !important;
//     color: var(--table-header-color);
//     font-size: var(--table-header-font-size);
//     // padding: 0 0.25rem;
//     text-transform: uppercase;
//     font-weight: 700;
//     padding: 0;
//     height: 40px;
//     // vertical-align: middle;
// }
.border-left-cell {
  position: relative;
}
.border-left-cell:after {
  content: '';
  position: absolute;
  top: 0;
  height: 100%;
  opacity: 1 !important;
  // width:2%;
  border-left: 2px solid #121827;
}
.border-left-cell-half {
  position: relative;
}

.border-left-cell-half:after {
  content: '';
  position: absolute;
  top: 0;
  height: 50%;
  opacity: 1 !important;
  // width:2%;
  border-left: 2px solid #121827;
}
.ExpCell {
  display: table-cell;
  text-align: left;
  // padding: 0 0.25rem;
  height: 50px;
  font-size: var(--table-text-font-size);
  color: var(--text-color);
  vertical-align: middle;
}
