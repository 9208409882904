#NetworkSelector {
    .networkScrollList{
        list-style-type: none;
        padding: 0;
    }
    .listItem{
        padding: 0.8rem 1rem;
        cursor: pointer;
    }
    min-height: 50vh;
    max-height: 55vh;
    overflow-y: auto;
}