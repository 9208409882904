.CreateTicket {
  .empty-description {
    color: #ea5455;
    font-size: 12px;
  }

  .ticket-file {
    background-color: #F4F8FF;
    // color: var(--primary);
  }

  .ticket-reference-input {
    outline: none;
    border: none;
    width: 45%;
  }

  .tilted-text {
    color: rgba(130, 134, 139, 1);
    font-style: italic;
    user-select: none;
  }

  .hover-show {
    visibility: hidden;
  }

  .selector-container:hover>.hover-show {
    visibility: visible;
  }

  .selector-container:hover {
    background-color: rgba(246, 246, 246, 1);
  }

  .editor-container {
    // min-height: calc(100vh - 300px);
  }

  .padding-left-custom {
    padding-left: 8.5px !important;
  }
}

.labelfont {
  font-size: 1em;
}

.selectBox {
  width: 100%;
  //   border: 1px solid #d8d6de;
  //   border-radius: 0.357rem;
}

.fileupload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-width: 2px;
  border-style: dashed;
  border-color: #5279ce;
  border-spacing: 10px;
  border-radius: 5px;
  // padding: 8px;
  width: 100%;
  // min-height: 130px;
}

.assign-venue-btn {
  display: flex;
  justify-content: center;
  font-size: 1.4em;
  margin-right: 4px;
  align-items: center;
}

.assign-btn-container {
  width: max-content;
}

.bin-icon {
  color: rgb(255, 141, 141);
}