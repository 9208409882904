.Actions-Component {
    text-align: center;
    margin-top: 1rem;
    .hoverable {
        &:hover {
          background-color: rgba(215, 214, 215, 0.367);
          transition: all 0.2s ease-in-out;
        }
      }
      .bg-color {
        background-color: rgba(82, 121, 206, 0.15) !important;
      }
    
    .wifi-scan{
      max-height:60vh;
      overflow:auto;

    }
}