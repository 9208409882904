.InfraBreakdown {
    .text-color--green {
        color: #28A745;
    }
    .bigger-text {
        // font-size: 17px;
        font-size: 1.2142857142857142857142857142857rem;
    }
    .font-weight--600 {
        font-weight: 600;
    }
}
.infraBreakdownTooltip {
    .text-color--green {
        color: #28A745;
    }
    .bigger-text {
        // font-size: 17px;
        font-size: 1.2142857142857142857142857142857rem;
    }
    color: var(--text-color);
    .font-weight--500 {
        font-weight: 500;
    }
    .font-weight--600 {
        font-weight: 600;
    }
    .breakdown-item {
        // min-width: 10rem;
    }
}