.VenueTree {
  .text-custom {
    color: #d9d9d9;
  }
  .border-custom {
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .custom-size {
    font-size: 1.4em;
  }
  .icon {
    width: 1.4rem;
  }
  .heightController {
    height: calc(100vh - 12.4rem);
    overflow: auto;
  }
  .p-relative {
    position: relative;
  }
  .button-padding {
    padding: 0.4rem;
    font-size: 1.4rem;
  }
  .venueList {
    list-style-type: none;
    padding-left: 1em;
  }
  .py-custom {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    margin-top: 0.2em;
    .options {
      opacity: 0;
      span {
        &:hover {
          font-weight: bold;
        }
      }
    }
    &:hover .options {
      opacity: 1;
      transition: all 0.2s ease-in;
    }
  }
  .hoverable {
    &:hover {
      background-color: #fefefe;
      transition: all 0.2s ease-in;
    }
  }
  .p-custom {
    padding: 0.4em;
  }
  .hover-bold {
    &:hover {
      font-weight: bold;
      transition: all 0.1s ease-in-out;
    }
  }
  .hover-child {
    &:hover {
      background-color: #edfaf8;
      transition: all 0.2s ease-in;
    }
  }
  .option-parent {
    border-radius: 50%;
    background-color: #c7c7c7;
    margin-right: 0.5em;
  }
  .option-child {
    border-radius: 50%;
    background-color: #ffff;
    margin-right: 0.5em;
  }
  .active-venue {
    background-color: #fafafa;
  }
  .white-icon {
    path {
      fill: #ffff;
    }
  }
  .blue-icon {
    path {
      fill: rgba(82, 121, 206, 1);
    }
  }
  .bullet {
    font-size: 0.9rem;
  }
  .filled {
    margin-left: 1.75rem;
  }
  .filled {
    margin-left: 1.75rem;
  }
  #org-list {
    height: min(calc(100vh - 360px), 900px);
    overflow: auto;
  }
  #org-list::-webkit-scrollbar-track {
    margin-top: 50px;
  }
}

.mapContainer {
  height: calc(100vh - 17.5rem);
}

.flag-icon {
  height: 24px;
  width: 24px;
  clip-path: circle();
  background-position: center;
  background-size: 42px 42px;
}