.Timeline {
    #venue-timeline-list{
      height: calc(100vh - 475px);
      overflow: auto;
    }
    .list-download-icon--disabled {
      cursor: not-allowed;
    }
    .cumulative {
        span {
          background-color: white;
          padding: 8px;
          &:hover {
            cursor: pointer;
          }
          &:nth-child(1) {
            border: 1px solid rgba(0, 0, 0, 0.421);
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
          }
          &:not(:nth-child(1)) {
            border-width: 1px 1px 1px 0px;
            border-color: rgba(0, 0, 0, 0.421);
            border-style: solid;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
          }
        }
        .selected {
          background-color: #5279CE;
          color: white;
        }
      }
}