.Authentication {
    Label{
        font-size: 0.857em;
    }
    .coa-label {
        font-size: small;
    }
    [type="checkbox"] {
        vertical-align:middle;
    }
    .NetworkImage {
        height: 150px;
    }
    .input--min-height {
        min-height: 6.5rem;
    }
    .main-form {
        // height: var(--network-main-form-height,67vh);
        height: calc(100vh - 220px);
        overflow-y: auto;
        overflow-x: hidden;
    }
    tr > td > .hover-show {
        visibility: hidden;
    }
    tr:hover > td > .hover-show {
        visibility: visible;
    }
    .fqdn-input-div {
        display: flex;
        gap: 0.5rem;
    }
    .fqdn-table-container {
        min-height: 180px;
        max-height: calc(100vh - 480px);
        overflow: auto;
        .fqdn-server-table {
            table-layout: fixed;
            > thead {
                position: sticky;
                top: 0;
            }
        }
        .fqdn-server-table > thead > tr {
            :nth-child(1) {
                width: 90%;
            }
            :nth-child(2) {
                width: 10%;
            }
        }
    }
    .fqdn-table-container::-webkit-scrollbar-track {
        margin-top: 3rem;
    }
}