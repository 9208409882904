.TimeProgress {
  min-width: 100px;
  background-color: #ff7940;
  text-align: center;
  font-size: 0.875rem;
  font-weight: 600;
  color: #fff;
  border-radius: 5px;
  .progress {
    height: 5px;
    border-radius: 0px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #ff7940;
  }
  .text-padding {
    padding: 0.4rem 0;
  }
}
.ListProgress {
  max-width: 100px;
  background-color: #ff794022;
  text-align: center;
  font-size: 0.75rem;
  font-weight: 600;
  color: var(--dark);
  border-radius: 5px;
  border: 1px solid #ff7940;
  margin: 0 0.25rem;
  .progress {
    height: 2px;
    border-radius: 0px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #ff794022;
  }
  .progress-bar {
    background-color: #ff7940;
  }
  .text-padding {
    padding: 0.2rem 0;
  }
}
