.QuoteList {
  .table-column {
    vertical-align: middle;
  }

  .second-row-color {
    background-color: #f3f3f3;
  }

  .quote-table::-webkit-scrollbar-track {
    margin-top: 42px;
  }
}

.top-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  // font-weight: 600;
}

.bg-color {
  background-color: rgba(82, 121, 206, 0.244) !important;
  padding-left: 0;
}

.search-button {
  // font-size: 2em;
}

.ellipsed-text {
  overflow: hidden;
  text-overflow: ellipsis;
}

.ticket-subject {
  max-width: 350px;
  inline-size: 350px;
  overflow-wrap: break-word;
}

.hover-bold {
  &:hover {
    color: #5789de;
  }
}

.table-row {}

.table-row:hover {
  background-color: rgba(82, 121, 206, 0.078);
}

#quote-table-id {
  height: calc(100vh - 180px);
  overflow: auto;
}

#quote-table-id-nested {
  height: calc(100vh - 225px);
  overflow: auto;
}