.Network {
    #venue-network-list{
      height: calc(100vh - 240px);
      overflow: auto;
    }
    .bg-color{
        background-color: rgba(80, 121, 206, 0.15) !important;
    }
    .band-badge{
      font-size: 1rem;
      margin-right: 0.4rem;
    }
    .hoverable {
        &:hover {
          background-color: rgba(215, 214, 215, 0.367);
          transition: all 0.2s ease-in-out;
        }
      }
}