.Header {
  .mt-custom {
    margin-top: 1em;
  }

  .selectBox {
    padding-bottom: 0.5rem;
  }

  .m-custom {
    margin-left: 1em;
    margin-top: -0.6em;
  }

  // .material-symbols-outlined {
  //   font-variation-settings:
  //   'FILL' 1,
  //   'wght' 400,
  //   'GRAD' 0,
  //   'opsz' 48
  // }

  .small {
    font-size: 1rem;
  }
}
