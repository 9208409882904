.Utilities {
  .vis-hidden {
    visibility: hidden;
  }
  .LowerDiv {
    background: #fbfbfb;
    border: 1px solid #eaeaea;
    border-radius: 8px;
  }
  .OutputHeading {
    color: var(--headings-color);
    font-weight: 600;
    // font-size: large;
  }
  .ClipBoardSpan {
    &:hover {
      background-color: whitesmoke;
    }
    font-size: small;
  }
  .OutputDiv {
    overflow: auto;
    max-height: 50vh;
    min-height: 25vh;
  }
  ::-webkit-scrollbar {
    width: 0.5rem;
  }
  ::-webkit-scrollbar-track {
    background-color: #e3e3e3;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #5279ce;
    border-radius: 1rem;
  }
  .defaultOutput {
    min-height: 30vh;
    font-size: var(--text-font-size);
  }
  .pv-half {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
.toastMessageClipboard {
  //    background-color: black;
  //    color: white;
  //    font-weight: bolder;
  //    width: 60%;
}
