.CustomToast {
  .error {
    color: rgba(234, 84, 85, 1);
  }
  // .success {
  //   color: var(--success);
  // }
  .custom-toast-title {
    font-weight: 500;
    font-size: 16px;
  }
  .custom-toast-message {
    margin-top: 4px;
  }
  .create-ticket-button {
    color: rgba(62, 94, 207, 1);
    font-weight: 700;
  }
}
