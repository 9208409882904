.LightBadge {
    margin: 0 0.25rem;
    .light-badge-custom{
        padding: 0.25rem 0.4rem;
        font-size: 0.75rem;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        max-width: 180px;
        min-width: 60px;
        min-height: 24px;
    }
}