// .labelfontauth {
//   font-size: 0.86em;
// }
.TextInputWithIcon {
  .labelfont {
    font-size: 1em;
  }
  .no-right-border {
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .textinput-with-icon {
    padding-left: 2.5em !important;
  }
  .textinput-border {
    border: 1px solid #d8d6de;
    border-radius: 0.357rem;
  }
  .textinput-icon {
    position: absolute;
    top: 0.5em;
    left: 1em;
  }
  .invalid-border{
    border-color: #ea5455 !important;
  }
  .default-tag{
    color: var(--text-color);
    cursor: pointer;
  }
}
.readonly-inputText:read-only {
  // background-color: white;
  color: var(--text-color);
  opacity: 100;
  cursor: default;
}
