.EmailShareModal {
  .ticket-email-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    // border: 1px solid #cccccc;
    padding: 7px;
    // overflow: auto;
    // border-radius: 4px;
  }
}
