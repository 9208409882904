.IdentityPermissions {
  .fw-medium {
    font-weight: 500;
  }
  .font-size--16 {
    font-size: 16px;
  }
  .footer {
    gap: 2em;
  }
  .text-overflow-ellipsis {
    text-overflow: ellipsis;
  }
  .data-cell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .select-dropdown {
    path {
      fill: #444 !important;
    }
  }
}
