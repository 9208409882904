.InfraDisabledCard {
    width: 39%;
    position: relative;
    .disabled-card-heading {
        // font-size: var(--headings-font-size);
        font-size: 1.43rem;
        font-weight: 500;
    }
    .margin-right-toggle {
        margin-right: 1.5rem;
    }
}