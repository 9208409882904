.FirmwareProgressGraph {
    padding-bottom: 6px;
    .firmware-widget-heading {
        min-height: 1.74rem;
        display: flex;
        align-items: center;
    }
    .legends {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 0.36rem;
        row-gap: 1rem;
    }
}