.SiteSelector {
    #single-infra-selector::-webkit-scrollbar-track {
      margin-top: 50px;
    }
    .empty-space {
      min-width: 1.5rem;
    }
    .make-black-icon {
      path {
        fill: #fff;
        stroke: #000;
        stroke-width: 0.6px;
      }
    }
    .bg-color--light-blue {
      background-color: #eff5ff !important;
      &:hover {
        background-color: #eff5ff !important;
      }
    }
    .font-size--larger {
      // font-size: 17px;
      font-size: 1.2142857142857142857142857142857rem;
    }
    .padding-right--24px {
        padding-right: 24px;
    }
}