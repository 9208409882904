.Settings {
  .invalid {
    border-color: red;
  }
  .error-field {
    font-size: 0.85em;
  }
  .autocomplete-label {
    font-size: 1rem;
  }

  .delete-dropdown {
    position: absolute;
    top: 0;
    right: -10px;
    margin: 0;
    z-index: 50;
  }

  .google-map-container {
    position: relative;
    min-height: 11em;
    width: 90%;
  }

  .map-switch-container {
    // height: 16px;
    position: absolute;
    border-radius: 2px;
    bottom: 0;
    right: 0;
  }
  .map-switch-button {
    background-color: white;
    padding: 4px;
  }
  .map-switch-button.leftsideborder {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }

  .map-switch-button.rightsideborder {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  .map-switch-button.active {
    background-color: #5279ce;
  }

  .street-icon {
    path {
      stroke: black;
    }
  }
  .street-icon.active {
    path {
      stroke: white;
    }
  }
  .map-icon {
    path {
      fill: black;
    }
  }
  .map-icon.active {
    path {
      fill: white;
    }
  }
}
.check-input {
  transform: scale(1.18);
}

.chip-input-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  border: 1px solid #cccccc;
  margin-top: 7px;
  padding: 7px;
  border-radius: 4px;
}

.chip-input-box.red-border {
  border-color: red;
}
.chip-input {
  border: 0;
  outline: none;
  width: 300px;
  vertical-align: top;
}
.margin-bottom-input {
  margin-bottom: 7px;
}

.check-input:focus {
  outline: none;
}

.chip-design {
  margin-right: 1em;
  margin-bottom: 7px;

  background-color: #e3e7f5;
  color: var(--primary);
  padding: 2px 18px;
  padding-right: 8px;
  border: 1px var(--primary) solid;
  border-radius: 20px;
}
