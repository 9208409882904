// .RadiusDetails {}
.radius-details-modal {
    max-width: 65vw;
    .radius-attributes-list-heading {
        color: var(--shasta-black);
        font-size: var(--headings-font-size);
    }
    .attributes-list {
        height: 45vh;
        overflow: auto;
    }
    .attributes-list::-webkit-scrollbar-track {
        margin-top: 40px;
    }
    th {
        position: sticky;
        top: 0;
    }
    .hoverable {
        &:hover {
            background-color: #f2f2f2;
            transition: all 0.3s ease-in;
        }
    }
}