.VenueStateDropdown {
    .venueStateGrp{
        // height: 38px;
        height: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        div{
            display: flex;
            align-items: center;
            justify-content: center;
            padding: auto;
            width: 100%;
            border: 2px solid #EAEAEA;
            font-size: 70%;
            // color: #000;
        }
        div:first-child {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
          }
        div:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        }
    }
  
}