.AlertInfraTable {
  .refresh-button {
    // border: 1px solid #b6b3b3;
    border-radius: 5px;
    color: #838181;
    .padded-icon {
      padding-left: 7px;
      padding-right: 7px;
    }
  }
  .info-icon {
    margin-left: 0.25rem;
    font-size: 1.4rem;
    transform: translateY(0.3rem);
    color: var(--primary);
  }
  #table-bod {
    display: block;
    height: min(calc(100vh - 480px), 900px);
    overflow: auto;
  }
  #table-bod::-webkit-scrollbar-track {
    margin-top: 40px;
  }
}
