.Infrastructure {
  #venue-dash-table {
    height: min(900px , calc(100vh - 220px));
    overflow: auto;
  }
  #venue-dash-table::-webkit-scrollbar-track {
    margin-top: 50px;
  }
  .notes-div {
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .hover-bold:hover {
    color: dodgerblue;
    cursor: pointer;
  }
  table {
    background-color: white;
  }
  .w-custom {
    width: 30%;
  }
  .bg-thead {
    background-color: rgba(80, 121, 206, 0.15) !important;
  }
  .heightController {
    max-height: 57vh;
    overflow: auto;
  }
  .secondary-icon {
    svg {
      path {
        stroke: #949494 !important;
      }
    }
  }
  .secondary-icon-fill {
    svg {
      path {
        stroke: #949494 !important;
        fill: #949494 !important;
      }
    }
  }
}

.success-top {
  position: absolute;
  top: -10%;
  right: 15%;
}
.task-img {
  position: relative;
  bottom: 0;
  left: 0;
}
.task-div {
  position: relative;
  overflow: visible;
  width: 38px;
  height: 38px;
}
