.HealthTimeline {
    user-select: none;
    #timeline-svg{
        cursor: crosshair;
        width: 100%;
        max-width: fit-content;
        height: 180px;
    }
    .svg-label{
        font-size: 0.4rem;
        user-select: none;
        font-weight: 800;
    }
    .svg-event{
        font-size: 0.4rem;
        font-weight: 600;
        user-select: none;
    }
    .svg-headings{
        font-weight: 600;
        font-size: 0.5rem;
        fill: #5E5873;
    }
    .svg-event-info{
        font-size: 0.8rem;
        user-select: none;
    }
    .border-5{
        border: 2px solid #eaeaea;
    }
    .rounded-top{
        border-bottom: 0;
    }
    
    .border-right-5{
        border-right: 4px solid #737373;
    }
    .p-custom{
        padding: 0.5rem 0;
    }
    .p-health{
        padding: 0.5rem;
        user-select: none;
    }
}
.tooltip-font{
    font-size: 0.8rem;
    font-weight: 500;
}