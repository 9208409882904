.CableDropWidget {
  background-color: #6f90d6;
  font-size: small;
  color: white;
  font-weight: bold;
  border: 2px solid white;
  .ap-widget-label {
    min-height: 40px;
    background-color: #5279ce;
    user-select: none;
  }
  .ap-widget-action {
    padding: 6px;
    cursor: pointer;
    &:hover {
      background-color: #587fc8;
      transition: 0.25s all ease-in;
    }
  }
}
