.DeleteInfraModal {
    .heading {
        font-size: var(--headings-font-size);
        color: var(--headings-color);
        font-weight: 500;
    }
    .InfraInfoWrapper {
        min-height: 3rem;
    }
    .ButtonsDiv {
        gap: 0.5rem;
    }
}