.OrganizationsList {
    .pl-custom{
    padding-left: 1.8rem;
    }
    .customSize {
    font-size: 1.4em;
    }
    .backButton {
    padding-top: 8px;
    padding-bottom: 8px;
    }
    .material-symbols-outlined.filled {
    font-variation-settings:
    'FILL' 1,
    'wght' 400,
    'GRAD' 0,
    'opsz' 48
    }
    min-width: min(600px, 100%);
    .icon {
    font-size: 1.3em;
    font-weight: bold;
    }
    .manageOverflow {
    height: 60vh;
    overflow-y: auto;
    }
    .expandableList {
    padding-left: 0;
    list-style-type: none;
    }
    .childOrg {
    margin-top: 1em;
    margin-bottom: 1em;
    .orgName {
        cursor: pointer;
        font-size: 1.1em;
        padding-bottom: 0.4em;
    }
    }
    .alignVert {
    display: flex;
    align-items: center;
    border-radius: 0.5em; 
    padding-top: 1em;
    &:hover{
        background-color: rgba(82, 121, 206, 0.1);
        color: #5279CE;
        transition: all 0.2s ease-in;
    }
    }
    .multiSelect {
        max-height: 35vh;
        overflow-y: auto;
    }
}