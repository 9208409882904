.Overview {
    --icon-size: 15%;
    .selectRow {
        padding-bottom: 0.5em;
      }
    .dateRangeRow {
        margin-bottom: 1.5em;
    }
    .columnWidth {
        table-layout: fixed;
    }
    .client-overview-dropdown {
        font-size: 1.57rem;
    }
    .small-font {
        font-size: small;
    }
    // table, th, td, tr {
    //     border: 1px solid black;
    // }
    .react-loading-skeleton {
        z-index: initial;
    }
    .cards-row > div {
        height: 190px;
    }
    .dropdown {
        width: 0.9rem;
    }
    .dropdown-toggle {
        height: 100%;
        font-size: 1.6rem;
        border-radius: 0.2em;
    }
    #client-info-card {
        padding: 1rem 0.5rem 0.5rem 0.5rem;
    }
    #client-info-card > div {
        // flex-grow: 1;
        // border: solid;
        width: 33%;
        .icon {
            height: var(--icon-size);
            width: var(--icon-size);
        }
        .grey-icon {
            path {
                fill: #B9B9C3;
            }
        }
        .icon-line {
            left: calc(50% + var(--icon-size));
            width: 70%;
            border-color: var(--primary);
        }
        .client-details > div {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            display: block;
        }
        .client-details[data-wired=true] > div {
            padding-left: 20%;
        }
        .client-details[data-topPadding=true] {
            margin-top: 2.75rem;
        }

    }
    .client-details {
        // padding-left: 2rem;
    }
    .pill {
        width: 38.9px;
        min-width: auto;
      }
    .ChartCard {
        height: 100%;
    }
    .scoped {
        height: 100%;
    }
    .topRow {
        height: 3em;
        color: var(--text-color)
    }
    .bottomRow {
        height: 3em;
        color: var(--text-color)
    }
    .refresh-icon {
        position: absolute;
        right: 0.5rem;
        top:0.5rem;
        width:24px !important;
        height: 24px;
        text-align: center;
        &:hover{
            background-color: #f9f9f9;
        }
    }
    #client-event-list{
        height: min(calc(100vh - 465px), 900px);
        overflow: auto;
    }
    #client-event-list[data-wireless="yes"] {
        height: min(calc(100vh - 525px), 900px);
    }
    #client-event-list::-webkit-scrollbar-track {
    margin-top: 50px;
    }
}