.OperationCounters {

  // .col--custom-padding {
  //   --padding: 0.5rem;
  //   padding-left: var(--padding);
  //   padding-right: var(--padding);
  // }
  .grid-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.7rem;
  }

  .count-bubble {
    --bubble-size: 1.2rem;
    position: absolute;
    // font-size: 4px;
    font-size: 0.6rem;
    height: var(--bubble-size);
    width: var(--bubble-size);
    transform: translateX(-50%) translateY(-50%);
    top: 0%;
    left: 100%;
    border-radius: 50%;
    border: 1px solid var(--primary);
    background-color: white;
    color: black;
    font-weight: 600;
  }

  .small-bubble {
    --bubble-size: 1rem;
    height: var(--bubble-size);
    width: var(--bubble-size);
    font-size: 0.5rem;
    transform: translateX(-50%) translateY(-40%);
  }

  .fill-black {
    path {
      fill: black;
    }
  }

  .stroke-black {
    path {
      stroke: black;
    }
  }

  .detail-list-container {
    // border-left: 1px solid black;
    // margin-left: 8px;
    padding-top: 8px;
    position: relative;
  }

  .alarm-dash-pointer {
    width: 6px;
    height: 7px;
    background-color: #444444;
    // border: 1px solid #444444;
    // border-radius: 6px;
    clip-path: circle();
    margin-right: 4px;
    margin-left: 4px;
  }

  .hide-bottom-object {
    width: 3px;
    height: calc(50% - 0.5px);
    position: absolute;
    background-color: white;
    bottom: 0;
    left: -1.4px;
  }
}

.FwScheduleTooltip {
  color: var(--text-color);

  .font-weight--500 {
    font-weight: 500;
  }

  .sub-venue-date-span {
    //12px
    font-size: 0.85714285714285714285714285714286rem;
  }
}