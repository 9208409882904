.Tabs {
  .Tab {
    text-decoration: none;
    color: var(--text);
    margin-right: 1.3em;
    padding-bottom: 0.6em;
    min-width: 10em;
    &:not(.ActiveTab):hover {
      color: var(--text);
      border-bottom: 2px solid rgb(154, 161, 253);
    }
  }
  .ActiveTab {
    border-bottom: 3px solid dodgerblue;
    background-color: var(--bs-light);
  }
  ul.inline {
    padding-left: 0px !important;
    padding-inline-start: 0px !important;
  }
  .disabledTab{
    padding-right:10px;
  }
}