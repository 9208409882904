.Notification {
    .opacity-50 {
      opacity: 0.5;
    }
    .refresh-button {
        border: 1px solid #b6b3b3;
        border-radius: 5px;
        color: #838181;
        .padded-icon {
          padding-left: 7px;
          padding-right: 7px;
        }
    }
    .selection-count-div {
      min-width: 5.4rem;
      text-align: right;
    }
    .acknowledgement-icon {
      //24px
      font-size: 1.71rem;
      line-height: 0.8;
    }
    .dropdown-menu {
      z-index: 41;
      .dropdown-item {
        gap: 0.5rem;
      }
    }
    .ellipsed-noti{
      overflow: hidden;
      overflow-wrap: anywhere;
      text-overflow: ellipsis;
    }
    .info-icon{
      margin-left: 0.35rem;
      font-size: 1.3rem;
      transform: translateY(0.3rem);
      color: var(--primary);
    }
    #table-bod{
      display:block;
      height: min(calc(100vh - 220px), 900px);
      overflow:auto;
    }
    #table-bod::-webkit-scrollbar-track {
      margin-top: 40px;
    }
}
