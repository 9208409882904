.Events {
    #infra-timeline-list{
        height: calc(100vh - 420px);
        overflow: auto;
    }
    .text-black{
        color:#000;
        font-weight: 600;
    }
    .impact-div{
        background-color: #FAFBFF;
        padding: 1rem;
        margin-bottom: 1rem;
    }
    .border{
        border: 1px solid #eaeaea !important;
    }
    // td{
    //     border-right: 1px solid #DADADA; 
    //     border-left: 1px solid #DADADA; 
    //     border-bottom: 1px solid #DADADAA0 
    // }
}