.SelectionDisplayPills {
    overflow-x: auto;
    min-height: 2.5rem;
    margin-bottom: 0.5rem;
    width: 100%;
    white-space: nowrap;
    .badgeSize {
        font-size:small;
    }
    .badgeContentDiv {
        margin: 0.3rem;
    }
}