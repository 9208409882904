.InfraImageModal {
  .grey-img-dot {
    width: 5px;
    height: 5px;
    background-color: #d9d9d9;
    border-radius: 50%;
    margin-right: 4px;
  }
  .selected-img-dot {
    width: 8px;
    height: 5px;
    background-color: #444444;
    border-radius: 5px;
    margin-right: 4px;
  }
}
