.MoveInfraModal {
    min-width: 40vw;
    .modal-title {
        font-size: var(--headings-font-size);
        font-weight: 500;
        line-height: 20.16px;
    }
    .modal-footer {
        .font-weight--500 {
            font-weight: 500;
        }
    }
    .buttons-div {
        gap: 0.5rem;
    }
    .VenueSelector {
        #venue-selector-list {
            height: 55vh;
        }
    }
    .heading {
        font-size: var(--headings-font-size);
        color: var(--headings-color);
    }

    .move-infra-modal-header {
        border-bottom: 1px solid rgba(34, 41, 47, 0.05);
    }
}