.NetworkImage {
    .title{
        text-transform: uppercase;
        padding: 0.5rem 0;
        font-weight: 500;
        font-size: 15px;
        margin-right: 7px;
    }
    .label{
        margin-top: 1rem;
        font-weight: 500;
        font-size: 14px;
        margin-right: 7px;
        transition: opacity 2000ms;
    }
    animation: fade-in 2s;
    @keyframes fade-in {
        0% {opacity: 0;}
        100% {opacity: 1;}
    }
}