.OrgList {
  #org-list{
    height: min(calc(100vh - 180px), 900px);
    overflow: auto;
  }
  #org-list::-webkit-scrollbar-track {
    margin-top: 50px;
  }
  .searchBox {
    width: 40%;
  }
  .bg-color {
    background-color: rgba(82, 121, 206, 0.15) !important;
  }
  .hoverable:hover {
    background-color: rgba(211, 211, 211, 0.306);
  }
  // .table{
  //   table-layout: fixed;
  // }
  .flag-icon{
    height: 24px;
    width: 24px;
    clip-path: circle();
    background-position: center;
    background-size: 42px 42px;
  }
  .switch-icon{
    margin-right: 1rem;
    cursor: pointer;
  }
  .font-size--larger {
    // font-size: 17px;
      font-size: 1.2142857142857142857142857142857rem;
  }
  .font-weight--600 {
    font-weight: 600;
  }

}

.fill-black{
    path{
      fill: black;
    }
}
.stroke-black{
  path{
    stroke: black;
  }
}