.AccessPointTypeItem {
  position: relative;
  cursor: grab;
  // width: 100px;

  &:active {
    cursor: grabbing;
  }

  .infra-type-name {
    text-align: right;
    // width: 100%;
    max-width: 100%;
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}