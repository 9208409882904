.VenueSelector {
  width: 100%;
  min-height: 55vh;
  .make-black-icon {
    path {
      fill: #fff;
      stroke: #000;
      stroke-width: 0.6px;
    }
  }
  .online-infra-count {
    font-size: 1.2142857142857142857142857142857rem;
  }
  .font-weight--600 {
    font-weight: 600;
  }
  .padding-left-chevron {
    padding-left: 24px;
  }
  .venueList {
    list-style-type: none;
    padding-left: 1em;
  }
  .py-custom {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    // margin-top: 0.2em;
    .options {
      opacity: 0;
      span {
        &:hover {
          font-weight: bold;
        }
      }
    }
    &:hover .options {
      opacity: 1;
      transition: all 0.3s ease-in;
    }
  }
  .hoverable {
    &:hover {
      background-color: #f2f2f2;
      transition: all 0.3s ease-in;
    }
  }
  .p-custom {
    padding: 0.4em;
  }
  .hover-bold {
    &:hover {
      font-weight: bold;
      transition: all 0.1s ease-in-out;
    }
  }
  .noVenueDiv {
    text-align: center;
  }
  .icon {
    width: 1.1rem;
  }
  #venue-selector-list {
    height: 55vh;
    overflow-y: auto;
  }
  .bg-color--light-blue {
    background-color: #EFF5FF;
  }
  .list-item {
    min-height: 3.86rem;
    display: flex;
    align-items: center;
    width: 100%;
  }
  .top-border {
    border-top: 1px solid #E6E6EB;
  }
  .list-top-div {
    height: 55px;
  }
  input[type='radio'] {
    appearance: none;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    transition: 0.2s all linear;
    border: 0.1rem solid var(--primary);
  }

  input[type='radio']:checked {
    border: 0.35rem solid var(--primary);
  }
}
