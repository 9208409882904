.LayoutDeletionConfirmation {
  .delete-warning-icon {
    font-size: 80px;
  }

  .ellipse-text-box {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    // text-wrap: wrap;
    // word-break: break-all;
  }
}