.CumulativeToggle {
    .textBlack {
        color: black;
        border-color: black;
      }
      .customButtonGroup {
        position: relative;
        display: inline-flex;
        vertical-align: middle;
      }
      .leftToggle {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border: 1px solid #D0D5DD !important;
      }
      .rightToggle {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border: 1px solid #D0D5DD !important;
      }
}