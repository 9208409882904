.MapModal {
    .backDrop{
        position: fixed;
        cursor: pointer;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0,0,0,0.5);
        z-index: 50;
      }
    .text-small{
      font-size: 0.85rem;
    }
    .height-map{
      height: 46px
    }
    .bold-pend-task{
      font-weight: 600;
      font-size: 1rem;
    }
    .ellipsis-text{
      text-overflow: ellipsis;
      overflow: hidden;
      text-wrap: nowrap;
    }
    .font-address{
      font-size: 0.75rem;
      font-weight: 600;
    }
    //   .main{
    //     position: absolute;
    //     left: 0;
    //     top: 0;
    //     width: 100vw;
    //     height: 100vh;
    //     z-index: 100;
       
    //   }
}