// .port-div{
//     border-radius: 2.5px;
//     display: inline-block;
//     margin: 0.5rem 0.1rem;
//     color: white;
//     font-size: 0.7rem;
//     font-weight: 800;
//     height: 2.4rem;
//     width: 2.4rem;
//     text-align: center;
// }
.switch-config-form {
    flex-grow: 1;
}
.wire-tooltip-status {
    max-width: 19rem;
    // text-align: start;
    .wire-tooltip-status-title {
        font-weight: 500;
        color: var(--shasta-black);
    }
}
.info-icon{
    cursor: pointer;
    margin-left: 0.5rem;
    font-size: 1.5rem;
}
.inherit-tag{
    background-color: #E7E7E7;
    padding: 0.2rem 0.5rem;
    font-size: 12px;
    border-radius: 4px;
}
.font-small{
    font-size: 0.14rem;
    font-weight: 500;
    color: white;
}
.fill-green{
    path{
        fill: #289A71;
    }
    rect{
        fill: #289A71;
    }
}
.fill-black{
    path{
        fill: #000;
    }
    rect{
        fill: #000;
    }
}
.fill-yellow{
    path{
        fill: #FFBE40;
    }
    rect{
        fill: #FFBE40;
    }
}
.fill-grey{
    path{
        fill: #808080;
    }
    rect{
        fill: #808080;
    }
}
.port-border{
    path{
        stroke-width: 2;
    }
    rect{
        stroke-width: 4;
    }
}
.port-border-none{
    rect,path{
        stroke-width: 0;
    }
}
.border-trunk{
    rect,path {
        stroke: #7A0303;
    }
}
.border-access{
    rect,path {
        stroke: #7367F0;
    }
}
.border-802{
    rect,path {
        stroke: #E218CE;
    }
}
.border-lag{
    rect,path {
        stroke: #E22C18;
    }
}
.border-none{
    path {
        stroke: none;
    }
}
.port-text{
    padding: 0;
    font-size: 0.1rem;
    text-align: center;
}
.port-div{
    border-radius: 0.01rem;
    color: white;
    font-size: 0.2rem;
    font-weight: 600;
    height: 100%;
    width: 100%;
    text-align: center;
}
.switch-label{
    display: flex;
    align-items: center;
    font-size: 0.75rem;
    font-weight: 600;
    margin: 0.5rem 0.3rem;
}
.switch-colour-label{
    height: 0.5rem;
    width: 2.4rem;
    margin-right: 0.3rem ;
}
.firstRow{
    font-size: 0.875rem;
    font-weight: 600;
}
.secondRow{
    font-size: 0.875rem;
    font-weight: 500;
}
.capitalize{
    text-transform: capitalize;
}
.list-icon-switch{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.75rem;
    border: #eaeaea solid 2px;
    border-radius: 15%;
  }
.PortModal {
    .font-weight--600 {
        font-weight: 600;
    }
    .color--shasta-black {
        color: var(--shasta-black);
    }
    .port-name{
        max-width: 120px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        word-break: break-all;
        overflow: hidden;
        overflow-wrap: anywhere;
        text-overflow: ellipsis;
    }
    .tabs-div {
        gap: 1rem;
        [data-active=true] {
            border-bottom: 3px solid dodgerblue;
            background-color: var(--bs-light);
        }
        :hover[data-active=false] {
            border-bottom: 2px solid rgb(154, 161, 253);
            opacity: 0.9;
        }
    }
    .tabs-div > div {
        padding-bottom: 0.6em;
    }
    .PortConfigTabContainer {
        height: calc(100vh - 170px);
        max-height: 52rem;
        overflow: auto;
        .ConfigHeading {
            font-weight: 500;
            color: var(--shasta-black);
            font-size: var(--headings-font-size);
        }
        .ConfigSubHeading {
            font-weight: 400;
            color: var(--shasta-black);
            font-size: var(--headings-font-size);
        }
    }
    .PortOverviewDiv {
        height: calc(100vh - 170px);
        max-height: 52rem;
        overflow: auto;
        .font-weight--500 {
            font-weight: 500;
        }
        .gap--10 {
            gap: 10px;
        }
        .TopDiv {
            display: grid;
            grid-template-columns: 1fr 6fr 1fr;
            grid-template-rows: max-content;
            grid-row-gap: 1rem;
            padding: 0 2rem;
            .SimplePortDiv {
                // border-radius: 20%;
                // height: 3.5rem;
                // background-color: green;
                // width: 3.5rem;
                padding-right: 1.5rem;
                display: flex;
                align-items: center;
                justify-content: end;
                color: white;
            }
            .port-number{
                position: absolute;
                width: 46px;
                text-align: center;
                top: 15%;
                // left: 40%;
            }
            .port-number[data-sfp='true']{
                top:25%
            }
            .SimplePortDiv[data-sfp='true'] {
                width: 4.5rem;
            }
            .CableDiv {
                display: flex;
                align-items: center;
                justify-content: stretch;
            }
            .CableTestDiv {
                display: flex;
                align-items: center;
                gap: 0.5rem;
            }
        }
        .BottomDiv {
            min-height: 22rem;
            margin-top: 1rem;
            display: grid;
            grid-template-columns: 1fr 2fr 1fr;
            grid-template-rows: max-content;
            .OperationalInfoList {
                list-style-type: none;
                padding-inline-start: 0;
                .OpInfoKey {
                    font-weight: 500;
                    color: var(--shasta-black);
                }
                li {
                    margin-top: 0.5rem;
                }
            }
            .CTWarningDiv {
                display: flex;
                gap: 1rem;
                flex-direction: column;
            }
            .ResultContainer {
                align-items: start;
                .ResultsDiv {
                    margin: 0 3rem;
                    width: 100%;
                    background-color: var(--color--site-blue);
                    padding: 1rem;
                    border-radius: 5px;
                    .SfpOpList {
                        list-style-type: none;
                        padding-inline-start: 0;
                        .SfpInfoKey {
                            font-weight: 500;
                        }
                        li {
                            margin-top: 0.3rem;
                        }
                    }
                    .WireDiv {
                        gap: 0.5rem;
                    }
                    .WireNo {
                        width: 1rem;
                        height: 20px;
                    }
                    .ResultPill {
                        top: 50%;
                        left: 50%;
                        transform: translateX(-50%) translateY(-50%);
                        --content-size: 0.86rem;
                        font-size: 0.86rem;
                        .ResultIcon {
                            height: var(--content-size);
                            width: var(--content-size);
                            margin-right: 0.25rem;
                        }
                    }
                    .ResultWire[data-color='green'] {
                        rect,path {
                            fill: #28A745
                        }
                    }
                    .ResultWire[data-color='orange'] {
                        rect,path {
                            fill: #F47D31
                        }
                    }
                    .ResultWire[data-color='brown'] {
                        rect,path {
                            fill: #7B3B00
                        }
                    }
                    .ResultWire[data-slash=false] {
                        .ResultWireSlash {
                            stroke: none
                        }
                    }
                    // .ResultWire[data-no-top-bottom=true] {
                        // .WireBox {
                            // stroke-dasharray: 
                        // }
                    // }
                }
                .ResultsDiv[data-warning='true'] {
                    margin: 0 5rem;
                }
            }
        }
        .TopDiv > div, .BottomDiv > div {
            display: flex;
            justify-content: center;
        }
    }
    .StatisticsDiv{
        height: calc(100vh - 170px);
        max-height: 52rem;
        overflow: auto;
    }
}