.LayoutWidget {
  background-color: #6f90d6;
  font-size: small;
  color: white;
  font-weight: bold;
  border: 2px solid white;
  .layout-widget-label {
    background-color: #5279CE;
    user-select: none;
  }
  .layout-widget-action {
    padding: 6px;
    cursor: pointer;
    &:hover {
      background-color: #587fc8;
      transition: 0.25s all ease-in;
    }
  }
}