.ApOverview {
  .info-heading {
    color: rgba(110, 124, 135, 1);
    font-size: 0.8rem;
    display: flex;
    justify-content: space-between;
  }
  .editFilled {
    &:hover {
      color: #000000 !important;
      transition: 0.3s all ease-in-out;
    }
    font-size: 1.2rem;
    font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 48;
  }
  .info {
    font-size: 1rem;
    font-weight: 800;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .ml-custom {
    margin-left: 0.1rem;
  }
  .fill-client-svg {
    path {
      fill: #444444 !important;
    }
  }
  .connect-text {
    color: #289a71;
    font-size: 1rem;
    font-weight: 700;
  }
  .success-badge {
    background-color: #289a71;
    border-radius: 5px;
    margin-right: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100px;
    min-height: 35px;
  }
  .client-badge {
    border: 1px solid #289a71;
    color: #289a71;
    font-size: 0.9rem;
    display: flex;
    padding: 0 0.4rem;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    min-width: 180px;
    min-height: 35px;
  }
  .fail-badge {
    background-color: #e02b1d;
    border-radius: 5px;
    margin-right: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100px;
    min-height: 35px;
  }
  .warning-badge {
    background-color: #e6af09;
    border-radius: 5px;
    margin-right: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100px;
    min-height: 35px;
  }
  .status-text {
    color: #fff;
    font-size: 1rem;
    font-weight: 600;
  }
  .dark-badge {
    background-color: #808080;
    border-radius: 5px;
    margin-right: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100px;
    min-height: 35px;
  }
  .bg-grey {
    background-color: rgb(249, 249, 249);
  }
  .quicklink-button-ticket {
    padding: 0.4rem 1rem;
    position: relative;
    display: inline-block;
    border: 2px solid #eaeaea;
    margin: 0 0.5rem;
    .main-text {
      font-size: 1rem;
      color: #333;
      margin-bottom: 0.2rem;
    }
    .sub-text {
      font-size: 0.75rem;
    }
  }
  .border-2 {
    border: 2px solid #ebe9f1;
  }
  .status-symbol {
    font-size: 1.8em;
  }
  .dropdown-toggle {
    border-radius: 0.2em;
    &:hover {
      cursor: pointer;
      background-color: rgba(211, 211, 211, 0.122);
    }
  }
  .console-frame {
    height: 80vh;
  }
  // .scheduleDate {
  // font-size: 0.8em;s
  // }
  .fullscreen {
    position: absolute;
    right: 15px;
    bottom: 15px;
    // background-color: #5279CE;
    background-color: #fff;
    border-radius: 25px;
  }
  div.relative {
    position: relative;
  }

  .bold-text {
    font-weight: 500;
  }

  .grey-img-dot {
    width: 5px;
    height: 5px;
    background-color: #d9d9d9;
    border-radius: 50%;
    margin-right: 4px;
  }
  .selected-img-dot {
    width: 8px;
    height: 5px;
    background-color: #444444;
    border-radius: 5px;
    margin-right: 4px;
  }

  .box {
    color: white;
    padding: 2px 4px;
    font-size: 12px;
    max-height: 1.9em;
  }
  .box.mode2g {
    background-color: #5279ce;
  }
  .box.mode5g {
    background-color: #50497a;
  }
  .box.mode6g {
    background-color: #86089c;
  }

  .float-this-right {
    position: absolute;
    right: 0;
  }

  .badge-custom {
    width: fit-content !important;
    display: inline !important;
  }

  .success-top-icon {
    position: absolute;
    top: -8px;
    right: -8px;
  }
  .success-top-icon-small {
    position: absolute;
    top: -8px;
    right: 0px;
  }
  .yellow-info-badge {
    background-color: #fff9e5;
    max-width: fit-content;
  }

  .copy-icon {
    visibility: hidden;
  }

  .copy-icon-active {
    visibility: visible;
  }

  .ellipse-text-box {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    // text-wrap: wrap;
    // word-break: break-all;
  }
}

.ap-img {
  max-height: 100%;
  max-width: 100%;
}
.ap-img-lg {
  max-width: 100%;
  max-height: 100%;
}
.inst-img {
  height: 80px;
  width: 200px;
  object-fit: contain;
}

.InfraFloorplanContainer {
  .slide-container {
    min-height: 200px;
    border-radius: 5px;
    width: 100%;
  }
  .border-slide-view {
    border: solid rgba(128, 128, 128, 0.5) 0.86px;
  }
  .slide-button {
    color: white;
    padding: 6px;
    border-radius: 50px;
    background-color: #5279ce;
  }
  .slide-button-disabled {
    color: white;
    padding: 6px;
    border-radius: 50px;
    background-color: #6c757d;
  }

  .slide-grey-dot {
    margin-right: 8px;
    width: 6px;
    height: 6px;
    border-radius: 10px;
    background-color: grey;
  }
  .slide-grey-dot-selected {
    margin-right: 8px;
    width: 18px;
    height: 10px;
    border-radius: 10px;
    background-color: grey;
  }
}
