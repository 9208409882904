.PendingTasksSubHeading {
    width: 200px;
    display: grid;
    grid-template-columns: 1fr 0.5fr;
    .padding-top--point-65 {
        padding-top: 0.65rem;
    }
    .text--normal-size {
        font-size: var(--text-font-size);
    }
    .text-color--shasta-black {
        color: var(--shata-black);
    }
    .progress-pend-task {
        height: 0.5rem;
    }
    .progress-bar {
        background-color: var(--progress-bar--grey);
    }
}