.ReleaseArticle {
    .hover-bold {
        &:hover {
          font-weight: bold;
          transition: all 0.1s ease-in-out;
        }
      }
    .padding-arrow{
      padding: 1.5rem;
      padding-top: 2rem;
    }
}