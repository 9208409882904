.WallsListItem {
  cursor: pointer;
  &:hover {
    // background-color: #eaeaea !important;
    transition: 0.25s ease-in-out all;
  }
  &:active {
    // background-color: #5279ce !important;
    color: white;
    transition: 0.25s ease-in-out all;
  }
  .wall-color-code {
    .material-symbols-outlined {
      font-size: 20px;
      font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 24;
    }
  }
}
