.VenueInfraWidget {
  .grp-btn-icon-div {
    line-height: 0;
    min-width: 0;
    height: 100%;
    color: var(--text-color);
    padding: 0.2rem;
    height: 2.5rem;
    width: 2.24rem;
  }
  .top-dropdown {
    z-index: 41;
    .dropdown-item {
      gap: 0.5rem;
    }
  }
  .BulkActions {
    height: 2.5rem;
  }
  .small-add-button[data-disabled='true'] {
    opacity: 0.65;
  }
  .ellipsed-text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .long-text-div {
    width: 110px;
  }
  --font-size-large: 1.2142857142857142857142857142857rem;

  .progress-bar {
    background-color: var(--progress-bar--grey);
  }

  .site-bg {
    background-color: #eff5ff !important;
  }
  .opacity-50 {
    opacity: 0.5;
  }

  input[type='checkbox'] {
    width: 15px;
    height: 15px;
    position: relative;
  }
  input[type='checkbox']:indeterminate::before {
    content: '';
    display: block;
    color: white;
    width: 15px;
    height: 15px;
    border-radius: 2px;
    background-color: #0f3fa6;
  }
  input[type='checkbox']:indeterminate:disabled::before {
    background-color: #cecfd1 !important;
  }
  input[type='checkbox']:indeterminate::after {
    content: '';
    display: block;
    width: 70%;
    height: 16%;
    background-color: transparent;
    border-radius: 10px;
    // border: solid white;
    // border-width: 2px 0 0 0;
    position: absolute;
    top: 42%;
    left: 16%;
  }
  .text-large {
    font-size: var(--font-size-large);
  }
  .online-infra-counter {
    font-size: 12px;
    .normal-size {
      font-size: 14px;
    }
    .font-weight--600 {
      font-weight: 600;
    }
  }

  .all-task-done-check {
    position: absolute;
    top: 0;
    left: 4px;
  }
  .all-task-bg {
    background-color: #e5f5ef;
    height: 30px;
    padding-top: 2px;
    border: 1px solid #28a745;
    border-radius: 5px;
  }
}
.InfraDeleteModal {
  .heading {
    font-size: var(--headings-font-size);
    color: var(--headings-color);
    font-weight: 500;
  }
  .ButtonsDiv {
    gap: 0.5rem;
  }
}
mark {
  padding: 0.2rem 0;
  color: none;
  background-color: #ffcc18;
}
