.BulkActions {
    gap: 1rem;
    .opacity-50 {
        opacity: 0.5;
    }
    .font-weight--500 {
        font-weight: 500;
    }
    .font-weight--200 {
        font-weight: 200;
    }
    .bulk-action-divider {
        height: 75%;
        border-left: 1px solid white;
    }
}