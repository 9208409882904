.chart-tooltip-box-container {
    font-size: 0.8rem;

}
.clip-path-remover{
    svg{
        path{
            clip-path: none !important;
        }
    }
}

.chart-tooltip-date-box {
    background-color: #d2dcdf;
    padding: 2px 12px;
}

.chart-tooltip-data-container {
    padding: 4px 12px;
}

.chart-tooltip-bold-text {
    font-weight: 700;
    color: 'black'
}

.chart-total-circle {
    background-color: rgba(255, 159, 67, 1);
    width: 8px;
    height: 8px;
    border-radius: 10px;
    margin-right: 12px;
}

.chart-ap-circle {
    background-color: rgba(220, 78, 255, 1);
    width: 8px;
    height: 8px;
    border-radius: 10px;
    margin-right: 12px;
}