.AddVenue {
    .autocomplete-input {
        display: block;
        width: 100%;
    }

    .autocomplete-label {
        font-size: 1em;
    }

    .invalid {
        border-color: red;
    }

    .error-field {
        font-size: 0.85em;
    }

    .min-height--address-input {
        min-height: 84px;
    }

    .min-height--input {
        min-height: 84px;
    }

    .min-height--reason-input {
        min-height: 114px;
    }

    .custom-drop-down-class {
        // background-color: #efefef !important;
        padding: 0.5rem 1rem;
        min-height: 38px;
        color: black !important;
        border-color: #d8d6de !important;

        &.disabled {
            background-color: #efefef !important;
        }
    }

    .google-map-container {
        position: relative;
        min-height: 11em;
        // width: 90%;
    }

    .map-switch-container {
        // height: 16px;
        position: absolute;
        border-radius: 2px;
        bottom: 0;
        right: 0;
    }

    .map-switch-button {
        background-color: white;
        padding: 4px;
    }

    .map-switch-button.leftsideborder {
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
    }

    .map-switch-button.rightsideborder {
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
    }

    .map-switch-button.active {
        background-color: #5279ce;
    }

    .street-icon {
        path {
            stroke: black;
        }
    }

    .street-icon.active {
        path {
            stroke: white;
        }
    }

    .map-icon {
        path {
            fill: black;
        }
    }

    .map-icon.active {
        path {
            fill: white;
        }
    }

    .addVenueBlock {
        height: calc(100vh - 90px);
    }

    #another-venues-table {
        max-height: calc(100vh - 400px);
        overflow-y: auto;
    }

    #another-venues-table::-webkit-scrollbar-track {
        margin-top: 42px !important;
    }


    td>div>.close-hover-show {
        visibility: hidden;
    }

    .another-venue-row:hover>td>div>.close-hover-show {
        visibility: visible;
    }

    #failed-venues-table {}

    .text-disabled-primary{
      color:  rgba(82, 121, 206, 0.7)
    }
}