.AlertVenueTable {
  #alert-table-wrapper {
    display: block;
    height: 300px;
    overflow-y: scroll;
  }

  #alert-table-wrapper::-webkit-scrollbar-track {
    margin-top: 50px;
  }
  .info-icon{
    margin-left: 0.35rem;
    font-size: 1.3rem;
    transform: translateY(0.3rem);
    color: var(--primary);
  }
  .ellipsed-noti{
    overflow: hidden;
    overflow-wrap: anywhere;
    text-overflow: ellipsis;
  }
  .all-venue-div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    direction: rtl;
    text-align: left;
  }
}
