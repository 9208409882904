.SearchIconAddon {
  .text-custom {
    color: #D9D9D9;
  }
  .border-custom {
    border-left: 0 !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  .custom-size {
    font-size: 1.4em;
  }
}