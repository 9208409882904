.Permissions {
  .text-overflow-ellipsis {
    text-overflow: ellipsis;
  }
  .text-decoration-underline {
    text-decoration: underline;
  }
  .table-layout--fixed {
    table-layout: fixed;
  }
  #venue-permission-list {
    overflow: auto;
    height: calc(100vh - 400px);
  }
  // .permission-table-list {
  //   td {
  //     padding-top: 5px;
  //     padding-bottom: 5px;
  //   }
  // }
}
