.OneL2Vlan {
    .heading {
        font-size: 16px;
        font-weight: 600;
    }
    .legend-rectangle {
        width: 23.932px;
        height: 5.235px;
        flex-shrink: 0;
    }
    .port {
        .disabled {
            background-color: black;
            color: white;
        }
        .status--connected {
            
        }
        .status--disconnected {
    
        }
        .type--trunk {
            border: 2px solid #7A0303;
        }
        .type--access {
            border: 2px solid #7367F0;
        }
        .type--none {

        }
    }
}
.l2-vlan-port-modal {
    .heading {
        font-size: 24px;
    }
    .legend-rectangle {
        width: 23.932px;
        height: 5.235px;
        flex-shrink: 0;
    }
}