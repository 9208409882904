.InstalledImageModal {
  .grey-img-dot {
    width: 5px;
    height: 5px;
    background-color: #d9d9d9;
    border-radius: 50%;
    margin-right: 4px;
  }
  .selected-img-dot {
    width: 8px;
    height: 5px;
    background-color: #444444;
    border-radius: 5px;
    margin-right: 4px;
  }
  .inst-img-container {
    height: 350px;
    max-height: 400px !important;
    // width: 150px !important;
    object-fit: contain;
  }
  .delete-img-icon {
    position: absolute;
    background-color: white;
    padding: 8px;
    top: -10px;
    right: -10px;
    border-radius: 50%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
}
