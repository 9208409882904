.PendingTaskBreakdown {
    .progress-pend-task{
        height: 0.5rem;
    }
    .progress-bar {
        background-color: var(--progress-bar--grey);
    }
    .bold-pend-task{
        margin-top: 0.35rem;
        font-size: 1.14352980745rem;
        // font-weight: 600;
        color: var(--shata-black);
    }
    .text-color--shasta-black {
        color: var(--shata-black);
    }
}
.TasksBreakdownTooltip {
    color: var(--text-color);
    --font-size-large: 1.2142857142857142857142857142857rem;
    .text-large {
        font-size: var(--font-size-large);
    }
    .font-weight--600 {
        font-weight: 600;
    }
    .font-weight--500 {
        font-weight: 500;
    }
    .text-color--shasta-black {
        color: var(--shata-black);
    }
}