.VenueBasicDetails {
  .venue-name {
    max-width: 75%;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .yellow-info-badge {
    background-color: #fff9e5;
    max-width: fit-content;
  }
  .row--equal-fit {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));

  }
  .gap--half-rem {
    gap: 0.5rem;
  }
  .address-title {
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    white-space: nowrap !important;
  }
  .pending-task-row {
    gap: 0.5rem;
  }
  .google-map-container {
    position: relative;
    min-height: 7.5em;
  }

  .map-switch-container {
    // height: 16px;
    position: absolute;
    border-radius: 2px;
    bottom: 0;
    right: 0;
  }
  .map-switch-button {
    background-color: white;
    padding: 4px;
  }
  .map-switch-button.leftsideborder {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }

  .map-switch-button.rightsideborder {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  .map-switch-button.active {
    background-color: #5279ce;
  }

  .street-icon {
    path {
      stroke: black;
    }
  }
  .street-icon.active {
    path {
      stroke: white;
    }
  }
  .map-icon {
    path {
      fill: black;
    }
  }
  .map-icon.active {
    path {
      fill: white;
    }
  }
}
