.FloorplanSlideView {
  .yellow-circle {
    margin-right: 4px;
    width: 12px;
    height: 12px;
    border-radius: 10px;
    background-color: #ffcc18;
  }
  .green-circle {
    margin-right: 4px;
    width: 12px;
    height: 12px;
    border-radius: 10px;
    background-color: #31af34;
  }
  .blue-circle {
    margin-right: 4px;
    width: 12px;
    height: 12px;
    border-radius: 10px;
    background-color: #5279ce;
  }
  .cable-drop-legend {
    margin-right: 4px;
    width: 12px;
    height: 12px;
    background-color: #acacac;
  }
  .min-height-check {
    min-height: 170px;
  }
}
