.ModelSettingNew {
  rect {
    cursor: pointer;
  }
  .ap-info {
    font-size: 0.875rem;
    overflow: hidden;
    display: flex;
    margin-top: 0.25rem;
    align-items: center;
  }
  .border-big {
    border: 1px solid #e2e5e7;
  }
  .fill-grey {
    path {
      fill: grey;
    }
  }
  .fill-black {
    path {
      fill: black;
    }
  }
  .opacity-0 {
    opacity: 0;
  }
  .vlan-text {
    font-weight: bold;
    font-size: 0.9rem;
  }
  .main-text {
    font-size: 1.4rem;
    font-weight: bold;
  }
  .small-text {
    font-size: 0.875rem;
    padding-top: 0.4rem;
  }
  .model-list {
    border: solid lightgray 2px;
    border-radius: 10px;
    margin: 0.4rem 0;
    min-height: 8vh;
    overflow: hidden;
    padding-top: 0.4rem;
    cursor: pointer;
    &:hover {
      border-color: #5279ce77;
    }
  }
  .selected {
    border-color: #5279ce !important;
  }

  .custom-drop-down {
    padding: 0 1rem;
    height: 2.6rem;
  }
}

.port-form-label {
  margin-top: 1rem;
  font-size: 1rem;
}
