.EditorSidebarItem {
  // border-top-left-radius: 10px;
  // border-bottom-left-radius: 10px;
  border-width: 1px;
  border-style: solid;
  // position: relative;
  // left: -2px;
  border-color: #d7d9d9;
  user-select: none;
  .sidebar-item-label {
    font-size: 9px;
  }
  &:hover {
    &:not(.editor-sidebar-item-selected) {
      transition: ease-in-out 0.2s all;
      background-color: #eaeaea;
    }
    cursor: pointer;
  }

  height: 45px;
  width: 45px;
}

.editor-sidebar-item-selected {
  transition: cubic-bezier(0, 1.5, 1, 1.5) 0.25s all;
  background-color: #5279ce;
  color: white;
  border-color: #d0d3d4;

  // border-right: 2px solid white;
  left: 2px;
  z-index: 50;
}
