.FilterButton[background='blue'] {
  background-color: var(--color--lightblue);
}
.FilterButton {
  position: relative;
  display: inline-block;
  border: 1px solid var(--7-border-input) !important;

  .btn-icon {
    border-radius: 5px;
    border: 1px solid !important;
    border-color: #eaeaea !important;
    background-color: #fff;
  }
  .filter-active {
    display: inline;
    position: absolute;
    top: -10%;
    right: -10%;
    background-color: orangered;
    z-index: 10;
    color: white;
    padding: 12%;
    clip-path: circle();
  }
  .filter-button-text {
    color: #777777;
    font-weight: 400;
  }
}
