.infra-support-div {
  max-height: 85vh;
  overflow: auto;
}

.support-modal-style {
  z-index: 3 !important;
}


.SupportModal {
  .hover-show {
    visibility: hidden;
  }

  .selector-container:hover>.hover-show {
    visibility: visible;
  }

  .selector-container:hover {
    background-color: rgba(246, 246, 246, 1);
  }

  .padding-left-custom {
    padding-left: 8.5px !important;
  }
}