.Activation {
    .full-width {
        display: block;
    }
    .networkImage{
        max-width:50%;
        padding-top: 30px;
    }
    #venue-dash-table {
        height: min(calc(100vh - 264px), 900px);
        overflow: auto;
    }
    .main-form {
        min-height: var(--network-main-form-height,67vh);
        overflow-y: auto;
        overflow-x: hidden;
    }
    .VenueSelectorNetworks {
        min-height: 10vh;
        max-height: 43vh;
    }
}