.SortingHeader {
    .filter-active{
        display: inline;
        position: absolute;
        top: -5px;
        right: -5px;
        background-color: orangered;
        z-index: 10;
        color: white;
        padding: 4px;
        clip-path: circle();
    }
}
    .filter-buttons {
        display: grid;
        gap: 1em;
        grid-auto-flow: column;
    }
    .filter-icon {
        background-color: white;
        border-radius: 2px;
        height: 16px;
        width: 16px;
        font-size:1.2em;
    }
    .done-icon {
        font-size: 1.5em;
        margin-right: 0.2em;
    }