.SupportTicketList {
  #venue-ticket-list {
    overflow: auto;
    max-height: calc(100vh - 450px);
  }

  .width-control{
    min-height: 40px;
  }

  .support-table-row {
    // height: 100px;
    td {
      padding-top: 10px;
      padding-bottom: 10px;
    }
    // height: 50px !important;
  }
  .support-table-row:hover {
    background-color: rgba(82, 121, 206, 0.078);
  }
}
