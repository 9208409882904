// .labelfontauth {
//   font-size: 0.86em;
// }
.TextInput {
  .labelfont {
    font-size: 1em;
    text-wrap: nowrap;
    margin-right: 4px;
  }
  .no-right-border {
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
.readonly-inputText:read-only {
  // background-color: white;
  color: var(--text-color);
  opacity: 100;
  cursor: default;
}
