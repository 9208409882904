.Toggle {
    .material-symbols-outlined {
        font-variation-settings:
        'FILL' 1,
        'wght' 400,
        'GRAD' 0,
        'opsz' 48
        }

    .toggle-font{
    font-size: 2.8rem;
    }
}