.DeviceList {
  .info-icon{
    margin-left: 0.35rem;
    font-size: 1.3rem;
    transform: translateY(0.3rem);
    color: var(--primary);
  }
  #client-list{
    height: min(calc(100vh - 385px), 900px);
    overflow: auto;
  }
  #client-list::-webkit-scrollbar-track {
    margin-top: 50px;
  }
  .searchRow {
    margin-bottom: 0.5rem;
  }
  .searchRow[data-no-info=true] {
    margin-bottom: 2.4rem;
  }
  .row-heading {
    font-size: 16px;
    font-weight: 500;
    color: var(--dashboard-heading-color);
  }
  .chart-row {
    // height: 206px;
  }
  --skeleton-loader-card--padding: 0.5rem;
  .skeleton-loader-card {
    padding: var(--skeleton-loader-card--padding);
    // height: calc(100% - var(--skeleton-loader-card--padding));
  }
  // .skeleton-loader {
  //   height: calc(100% - var(--skeleton-loader-card--padding));
  // }
  .table {
    table-layout: fixed;
  }
  .table thead th {
    border-bottom: none;
  }
  td {
    overflow-wrap: break-word;
  }
  .longValue {
    overflow-wrap: normal;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .quickfilter-icon {
    --icon-size: 22px;
    height: var(--icon-size);
    width: var(--icon-size);
    path {
      fill: #808080;
    }
  }
  .name--margin {
    margin-left: 25px;
  }
  .device-name-span {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: anywhere;
    padding-top: 0.15rem;
  }
  .hoverable {
      &:hover {
        background-color: rgba(215, 214, 215, 0.367);
        transition: all 0.2s ease-in-out;
      }
    }
    .bg-color {
      background-color: rgba(85, 128, 219, 0.301) !important;
    }
  
  .hover-bold {
    &:hover {
      color: #5789DE;
    }
  }
  .infra-count {
    --bubble-size: 0.9rem;
    position: absolute;
    // font-size: 4px;
    font-size: 0.5rem;
    height: var(--bubble-size);
    width: var(--bubble-size);
    transform: translateX(-50%) translateY(-50%);
    top: 20%;
    left: 100%;
    border-radius: 50%;
    background-color: var(--primary);
    color: white;
  }
  .multi-click {
    color: #3E5ECF;
    font-weight: 500;
  }
  .pill {
    width: 33px;
    min-width: auto;
  }

}

.light-green-bg {
  background-color: rgb(210, 255, 210);
  transition: all 1s ease-in-out;
}

.white-bg {
  background-color: white;
  transition: all 0.2s ease-in-out;
}

.client-infra-tooltip {
  // table, th, td {
  //   border: 1px solid black;
  // }

  .tooltip-headers{
    color: #6E6B7B;
    font-weight: 600;
  }
  th, td {
    padding: 0.5rem !important;
    border: none;
    font-size: 1rem;
  }
}