.Canvas {
  height: 100%;

  .canvas-widgets {
    position: absolute;
    top: 3px;
    right: 3px;
    padding: 4px;
    z-index: 40;
  }

  .canvas-footer {
    position: absolute;
    bottom: 50px;
    // right: 3px;
    width: 100%;
    border: 1px solid #d4d4d4;
    background-color: white;
    padding: 4px;
    z-index: 42;
  }

  .canvas-playground {
    height: 100%;
  }

  .ap-legend {
    display: inline-block;
    height: 16px;
    width: 16px;
    background-color: #ffcc18;
    clip-path: circle();
  }

  .cable-drop-legend {
    display: inline-block;
    height: 16px;
    width: 16px;
    background-color: #acacac;
  }

  // Being used in CableDrop.js
  .cable-name-input {
    font-size: 0.5em !important;
  }

  .cable-name-input:focus {
    outline: none;
  }

  .add-infra-button {
    background-color: var(--primary);
    color: white;
    padding: 4px;
    font-size: 6px;
    border-radius: 2px;
    margin-top: 7px;
    margin-bottom: 5px;
  }

  .ap-close-button {
    background-color: white;
    position: absolute;
    top: -6px;
    right: -6px;
    font-size: 4px;
    // border: solid 1px;
    border-radius: 1px;
    width: 12px;
    height: 12px;
  }
}

input[type='radio'] {
  appearance: none;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  transition: 0.2s all linear;
  border: 0.1rem solid var(--primary);
}

input[type='radio']:checked {
  border: 0.35rem solid var(--primary);
}