.Header {
  .heading {
    padding-top: 1.4em;
    padding-bottom: 1.8em;
  }
  .circular-badge{
    color: var(--primary);
    display: flex;
    font-size: 0.85rem;
    font-weight: 600;
    align-items: center;
    justify-content: space-between;
    border: 2px solid var(--primary);
    border-radius: 50px;
    padding: 0.1rem 0.675rem;
    background-color: #5279CE20;
  }
  .circular-badge-danger{
    color: var(--danger);
    display: flex;
    font-size: 0.85rem;
    font-weight: 600;
    align-items: center;
    justify-content: space-between;
    border: 2px solid var(--danger);
    border-radius: 50px;
    padding: 0.1rem 0.675rem;
    background-color: #EA545520;
  }
  .text-truncate{
    text-overflow: ellipsis;
    overflow: hidden;
  }
}