.VenueStateGroup {
    .live-black{
        svg{
            #g{
                path{
                    fill: #000;
                }
            }
            path{
              stroke: #000;
            }
            line{
              stroke: #000
            }
          }
    }

    .devolping-black{
        svg{
            path{
              stroke: #000;
            }
            line{
              stroke: #000
            }
            polyline{
              stroke: #000;
            }
          }
    }

    .deployed-black{
        svg{
            path{
              fill: #000;
            }
            line{
              stroke: #000
            }
            polyline{
              stroke: #000;
            }
            circle{
              stroke: #000;
            }
          }
    }

		.live-green{
			svg{
					#g{
							path{
									fill: var(--success);
							}
					}
					path{
						stroke: var(--success);
					}
					line{
						stroke: var(--success)
					}
				}
	}

	.devolping-green{
			svg{
					path{
						stroke: var(--success);
					}
					line{
						stroke: var(--success)
					}
					polyline{
						stroke: var(--success);
					}
				}
	}

	.deployed-green{
			svg{
					path{
						fill: var(--success);
					}
					line{
						stroke: var(--success)
					}
					polyline{
						stroke: var(--success);
					}
					circle{
						stroke: var(--success);
					}
				}
	}
  
}