.header {
  margin-top: 5px;
  margin-bottom: 0px;
}

.daterange-select {
  min-width: 120px;
  font-size: 0.85rem !important;
  padding: 0 !important;
}

.empty-div {
  min-height: 15px;
}

.chartcard-legend-name {
  font-size: var(--legend-font-size);
}

.chartcard-legend-circle {
  padding: 4px;
  border-radius: 50%;
}

.ChartCard {
  .legends-container {
    max-width: 55%;
    overflow: hidden
  }

  .clip-path-remover {
    svg {
      path {
        clip-path: none !important;
      }
    }
  }
}

.progress-pend-task-chart {
  height: 0.8rem;
  margin-bottom: 2.5rem;
}

.progress-pend-task-chart[data-color='light-grey'] {
  div {
    background-color: var(--progress-bar--grey);
  }
}

.progress-pend-task-chart[data-color='success'] {
  div {
    background-color: var(--success);
  }
}

.bg-light-grey {
  background-color: var(--progress-bar--grey);
}

.refresh-button {
  // border: 1px solid #b6b3b3;
  border-radius: 5px;
  font-weight: 500;
  font-size: 0.85rem;
  padding: 1rem;

  .padded-icon {
    font-size: 1.25rem;
    padding-left: 7px;
    padding-right: 7px;
  }
}

.row-heading {
  font-size: 16px;
  font-weight: 500;
  color: var(--dashboard-heading-color) !important;
}

.negative-trend {
  // border: 1px solid #E02B1D;
  color: #e02b1d;
  // background-color: #E02B1D22;
  // padding: 0.1rem 0 0.2rem 0.5rem;
  font-size: 0.75rem;
}

.text-color--black {
  color: var(--text);
}

.positive-trend {
  // border: 1px solid #13A877;
  color: #13a877;
  // background-color: #13A87722;
  font-size: 0.75rem;
}

.red-icon {
  path {
    fill: red;
  }
}

.green-icon {
  path {
    fill: var(--success);
  }
}

.red-icon-stroke {
  path {
    stroke: red;
  }
}

.green-icon-stroke {
  path {
    stroke: var(--success);
  }
}

.rounded-card {
  background-color: white;
  // border: 1px solid rgba(238, 237, 238, 1);
  border-radius: 5px;
  padding: 6px 15px;
  // margin-bottom: 10px;
  height: 100%;
}

.chart-title {
  font-weight: 500;
  font-size: var(--chart-title-font-size) !important;
  margin-right: 7px;
}

.chart-heading {
  font-size: var(--chart-title-font-size);
  font-weight: 600;
}

.chart-heading-single {
  font-size: 1rem;
  font-weight: 500;
}

.chart-buttons {
  font-size: 20px;
  margin-left: 5px;
}

.color-pill {
  padding: 3px 10px 0px 10px;
  background-color: rgba(91, 212, 172, 0.219);
  color: rgba(0, 142, 95, 1);
  border-radius: 21.5465px;
  font-weight: 500;
  font-size: 13px;
}

.close-button {
  cursor: pointer;

  .cls-1 {
    stroke: #444;
  }
}

.color-pill-2 {
  padding: 3px 10px 0px 10px;
  background-color: rgba(91, 212, 172, 0.219);
  color: rgba(0, 142, 95, 1);
  border-radius: 21.5465px;
  font-weight: 500;
  font-size: 13px;
  margin-left: 3px;
}

.color-pill-3 {
  padding: 3px 10px 0px 10px;
  background-color: rgba(91, 212, 172, 0.219);
  color: rgba(0, 142, 95, 1);
  border-radius: 21.5465px;
  font-weight: 500;
  margin-left: 3px;
  font-size: 13px;
}

.support-heading {
  font-size: 12px;
  font-weight: 400;
  // color: rgba(102, 102, 102, 1);
}

.require-heading {
  color: #000;
  font-weight: 600;
}

.support-number {
  font-size: 18px;
  font-weight: 800;
}

.text-underline {
  text-decoration: underline;
}

.linediv {
  background-color: rgba(234, 234, 234, 1);
  width: 1px;
}

.support-ticket-item {
  font-size: 12px;
}

.support-ticket-item-group {
  font-weight: 500;
  padding: 0.3rem 0.1rem;
  color: black;

  &:hover {
    padding: 5px 3px;
    color: #5279ce;
    transition: ease 0.4s;
    cursor: pointer;

    .support-ticket-item {
      text-decoration: underline;
    }
  }

  margin: 0 0 1px 0;
  border-radius: 5px;
}

.flexible {
  font-size: small;
}

.zoom-btn {
  text-align: center;
  padding: 0.3rem 1rem;
  max-height: 32px;
  cursor: pointer;

  &.left-side {
    border: 2px solid #eaeaea;
    border-right: 0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &.right-side {
    border: 2px solid #eaeaea;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

// @media screen and (max-width: 1500px) {
//   .flexible {
//     font-size: small;
//   }
// }

// @media screen and (max-width: 1400px) {
//   .flexible {
//     font-size: small;
//   }
// }

// @media screen and (max-width: 992px) {
//   .flexible {
//     font-size: small
//   }
// }

.horizontal {
  font-size: small;
}

.rightPush {
  padding-right: 3.5px;
}

.leftPush {
  padding-left: 3.5px;
}

.text-lg {
  font-size: 2rem;
  font-weight: 600;
}

.support-ticket-item-width {
  width: 36rem;
}

.scoped {
  @keyframes react-loading-skeleton {
    100% {
      transform: translateX(100%);
    }
  }

  .react-loading-skeleton {
    --base-color: #ebebeb;
    --highlight-color: #f5f5f5;
    --animation-duration: 1.5s;
    --animation-direction: normal;
    --pseudo-element-display: block;
    /* Enable animation */

    background-color: var(--base-color);

    width: 100%;
    border-radius: 0.25rem;
    display: inline-flex;
    line-height: 1;

    position: relative;
    overflow: hidden;
    z-index: 1;
    /* Necessary for overflow: hidden to work correctly in Safari */
  }

  .react-loading-skeleton::after {
    content: ' ';
    display: var(--pseudo-element-display);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-repeat: no-repeat;
    background-image: linear-gradient(90deg,
        var(--base-color),
        var(--highlight-color),
        var(--base-color));
    transform: translateX(-100%);

    animation-name: react-loading-skeleton;
    animation-direction: var(--animation-direction);
    animation-duration: var(--animation-duration);
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }

  @media (prefers-reduced-motion) {
    .react-loading-skeleton {
      --pseudo-element-display: none;
      /* Disable animation */
    }
  }
}

.NDashboard {


  .widgets-row {
    min-height: 4.25rem;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    margin-bottom: 1rem;
    gap: 0.7rem;
  }

  .refresh-button {
    margin-left: 1rem;
  }

  .donut-chart {
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
  }

  .OrgWidget {
    #org-list {
      height: 320px;
      overflow: auto;
    }

    #org-list-fullscreen {
      height: calc(100vh - 275px);
      overflow: auto;
    }

    #org-list-fullscreen::-webkit-scrollbar-track {
      margin-top: 3.5625rem;
    }

  }


}

.progress-chart {
  position: relative;

  .main-text {
    font-size: 0.75rem;
  }

  .backdrop {
    background-color: #22292f;
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 50;
    opacity: 0.5;
  }
}

.progress-expand {
  position: absolute;
  padding: 1rem;
  left: 0;
  top: 0;
  z-index: 51;
  width: 100%;
  background-color: #fff;

  .expand-text {
    font-size: 0.875rem;
  }
}

.rotate-label {
  transform: rotateZ(270deg);
  min-width: 150px;
  min-height: 100px;
  margin: -1.5rem;
  text-align: center;
}