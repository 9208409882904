.SwitchTypeItem {
  position: relative;
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
  .infra-type-name {
    text-align: right;
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
