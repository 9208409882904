.InventoryWidget {
    .inv-widget-select__indicator {
        padding: 0;
    }
}
.InventoryWidgetContent {
    &[data-ismodal] {
        min-height: 40vh;
    }
    &[data-isloader] {
        padding-top: 2rem;
    }
    &[data-ismodal][data-isloader] {
        padding-top: 3rem;
    }
    .heading {
        font-weight: 500;
        font-size: 0.875rem
    }
    .progress {
        margin-top: 1rem;
        height: 12px;
    }
    .legends {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 0.36rem;
        row-gap: 1rem;
        &[data-unorganized] {
            display: flex;
            flex-wrap: wrap;
            gap: 1rem;
            row-gap: 0.5rem;
        }
        .legend-color {
            height: 5px; 
            width: 18px; 
            margin-right: 0.5rem,
        }
        .legend-text {
            font-size: 0.75rem;
            white-space: nowrap;
        }
        .text-end {
            text-align: end;
        }
    }
}