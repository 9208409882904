.oneFilter {
  font-weight: 600;
}
.disabledFilter {
  font-weight: 600;
}
.note {
font-size: smaller;
}
.optionsDiv {
//   padding: 0.6rem;
gap: 0.5rem;
padding: 1rem;
background-color: #F7F8FA;
}
.filter-search-box{
  margin-bottom: 0.8rem;
}
.overflowAuto {
overflow: auto;
}
.filter-flag-icon{
  height: 24px;
  width: 24px;
  clip-path: circle();
  background-position: center;
  background-size: 42px 42px;
}
.not-found-filter-text {
  font-size: calc(var(--text-font-size) - 0.14rem);
}