.PlusButton {
    // padding: 0.5rem;
    height: 38px;
    width: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #b6b3bf;
    &:hover{
        border: 1px solid #1C6BD6;
        background-color: #EAEAEA55;
    }
    &.disable{
        background-color: #EAEAEA;
        border-color: #D8D6DE;
    }
    .active{
        .cls-add-1{
            stroke: #000 !important; 
        }
    }
    .disable{
        .cls-add-1{
        stroke: #777 !important; 
        }
    }
}