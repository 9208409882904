.GroupButton {
  display: flex;
  div:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  div:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

.grp-btn-custom {
  padding: 0.45rem 0.55rem;
  background-color: white;
  cursor: pointer;
  text-align: center;
  min-width: 80px;
  font-weight: 500;
  user-select: none;
  color: var(--secondary);
  border: 1px solid #eaeaea;
  &.active {
    color: var(--text-color);
    background-color: var(--color--lightblue);
  }
}
.grp-btn-custom[data-clickDisabled=true] {
  cursor: not-allowed;
}

  .grp-btn-state {
    padding: 0.45rem 0.45rem;
    background-color: transparent;
    text-align: center;
    // min-width: 80px;
    font-weight: 500;
    color: black;
    font-size: 6px;
    user-select: none;
    // color: var(--secondary);
    border: 1px solid #eaeaea;
    &.active {
      // color: var(--text-color);
      background-color: var(--primary);
    }

  }
