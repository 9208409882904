.InfraInsightCharts {
  .daterange-selector {
    min-width: 150px;
  }
  .padRight {
    padding-right: 3.5px;
  }
  .padLeft {
    padding-left: 3.5px;
  }
}
