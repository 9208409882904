.AnalysisEditor {
  display: grid;
  height: calc(100vh - 57px);
  grid-template-rows: auto;
  // grid-template-columns: 500px auto;
  grid-template-columns: auto;
  grid-template-areas: 'map';
  .map-area {
    position: relative;
    grid-area: map;
  }
  .selector-area {
    position: relative;
    grid-area: selector;
    border-left: 1px solid #d0d3d4;
    background-color: #f7f8fa;
    .available-layouts-list {
      overflow-y: auto;
      height: calc(100vh - 109px);
    }
  }
}
