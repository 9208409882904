.Editor {
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  height: 100vh;
  width: 100vw;
  border-color: dodgerblue;
  border-style: solid;

  .editor-header {
    height: 60px;
    .editor-heading {
      font-weight: 600;
    }
    .save-button {
      padding: 7.5px;
      min-width: 60px;
    }
    .edit-marker {
      border-radius: 50%;
      padding: 10px;
    }
    .editor-name {
      min-width: 320px;
      max-width: 320px;
    }
    .editor-close {
      .editor-close-icon {
        font-size: 40px;
        padding: 8px;
        font-weight: 200;
      }
      &:hover {
        background-color: #eaeaea;
      }
    }
  }
  .top-mode-bar {
    background-color: white !important;
    border: solid 1px #d7d9d9;
  }
  .editor-area {
    width: 100vw;
    height: calc(100vh - 100px);
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 350px auto;
    grid-template-areas: 'sidebar playground';
    .sidebar {
      grid-area: sidebar;
    }
    .playground {
      grid-area: playground;
      border-left: 2px solid #323232;
    }
  }
}
