.ColumnHeader {
  .filter-active {
    display: inline;
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: orangered;
    z-index: 10;
    color: white;
    padding: 4px;
    clip-path: circle();
  }
  .m-adjust {
    margin-top: 0.3em;
  }

  .left-correct-padding {
    padding-left: 1.1em;
  }
}
.filter-buttons {
  display: grid;
  gap: 1em;
  grid-auto-flow: column;
}
.filter-icon {
  border-radius: 2px;
  height: 16px;
  width: 16px;
  font-size: 1.2em;
}
.done-icon {
  font-size: 1.5em;
  margin-right: 0.2em;
}
//makes arrow white, with no border, for popovers
// .arrow {
//     border-bottom-color: white !important;
// }
// .arrow::after {
//     border-bottom-color: white !important;
// }
// .arrow::before {
//     border-bottom-color: white !important;
// }
