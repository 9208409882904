.L2Vlan {
    thead {
        tr {
            th {
                border-bottom: none;
                border-top: none;
            }
            th:first-child {
                border-top-left-radius: 10px;
            }
            th:last-child {
                border-top-right-radius: 10px;
            }
        }
    }
    .ports-ellipsed {
        max-width: 25%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    .content-div {
        min-height: 80vh;
    }
    .table-view {
        table-layout: fixed;
    }
}