.NewOverview {
  .noti-pill {
    background-color: #fbfbfb;
  }
  .pending-task-row {
    gap: 0.5rem;
  }
  .cumulative {
    font-size: var(--text-font-small-size) !important;
    span {
      background-color: white;
      padding: 8px;
      &:hover {
        cursor: pointer;
      }
      &:nth-child(1) {
        border: 1px solid rgba(0, 0, 0, 0.421);
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
      &:not(:nth-child(1)) {
        border-width: 1px 1px 1px 0px;
        border-color: rgba(0, 0, 0, 0.421);
        border-style: solid;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
    .selected {
      background-color: #5279ce;
      color: white;
    }
  }
  .infra-error-info {
    background-color: rgba(255, 159, 67, 0.14);
    border-radius: 10px;
    padding: 0.3em 0.5em;
    cursor: default;
  }
  .deployment-task-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .custom-drop-down-class {
    // background-color: #efefef !important;
    padding: 0.5rem 1rem;
    min-height: 38px;
    color: black !important;
    border-color: #d8d6de !important;
    &.disabled {
      background-color: #efefef !important;
    }
  }
  .custom-drop-down-class:hover {
    // background-color: #efefef !important;
  }
  .custom-drop-down-class:focus {
    // background-color: white !important;
  }
  .py-custom {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .custom-toggle {
    display: inline-block;
    color: #444;
  }
  // .hover-active:hover {
  //   background-color: #6495ff;
  // }
  .slide-container {
    min-height: 200px;
    border-radius: 5px;
    width: 100%;
  }
  .border-slide-view {
    border: solid rgba(128, 128, 128, 0.5) 0.86px;
  }
  .slide-button {
    color: var(--primary);
    // background-color: #5279ce;
  }
  .slide-button-disabled {
    color: var(--primary);
    // background-color: #6c757d;
  }

  .slide-grey-dot {
    margin-right: 8px;
    width: 6px;
    height: 6px;
    border-radius: 10px;
    background-color: grey;
  }
  .slide-grey-dot-selected {
    margin-right: 8px;
    width: 18px;
    height: 10px;
    border-radius: 10px;
    background-color: grey;
  }
}
