.FilterSetter {
    position: relative;
    z-index: 1050;
    display: block;
    .filterDiv {
        display: block;
        // background-color: white;
        position: fixed;
        bottom: 0;
        right: -100%;
        width: 25%;
        height: 100vh;
        // width: 500px;
        // margin-right: 1rem;
        z-index: 1050
    }
    .move-in {
        animation-duration: 0.7s;
        animation-name: moveInFromRight;
        animation-fill-mode: forwards;
    }
    @keyframes moveInFromRight {
        from {right: -100%;}
        to {right: 0%;}
    }
}