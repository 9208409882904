.LayoutTooltipRenderer {
  .layout-name {
    font-weight: 700;
  }
  .layout-updated-on {
    font-size: 11px;
  }
  .layout-type {
    font-size: 13px;
  }
  .layout-options-icon {
    font-size: 20px;
  }
}
